<template>
    <div class="about">
        <!-- <SectionHeader title="Presentación"/>   -->
       
        <div class="container">
            <SectionTitle title="Presentación"/><br>
            <p class="text-justify">La mosca del Mediterráneo es una de las plagas más destructivas que existen en el mundo, tiene
una gran capacidad para reproducirse y es capaz de acabar con plantaciones enteras de frutales.
Suelen infestar frutas con o sin valor comercial, pero luego migran hacia plantaciones en gran
escala y depositan sus huevos en los frutos maduros de cascara blanda. A veces se detecta hasta
que la fruta ha sido comercializada y para entonces, la larva o gusano se ha desarrollado. Uno de
los grandes problemas es que la larva, puede ser transportada de un lugar a otro a través de fruta
infestada y luego emerger como mosca adulta e infestar los cultivos.</p>

            <p class="text-justify">En Guatemala, el Programa MOSCAMED es la institución oficial encargada de la detección, control,
supresión y erradicación de la mosca del Mediterráneo de su territorio y sus actividades de trabajo
se orientan a detectar la presencia de la plaga y suprimirla con una integración de controles los
brotes o detecciones en las áreas de influencia, concentrándose en tres áreas de trabajo: Área
Libre, Área de Baja Prevalencia y Área de Supresión.</p>

            <p class="text-justify">El Programa MOSCAMED opera desde los siguientes centros de trabajo: Oficinas Centrales en
Guatemala; Centro de Operaciones Petén con sede en Santa Elena, Petén; Centro de Operaciones
de la Franja Transversal del Norte con sede en Fray Bartolomé de las Casas, Alta Verapaz; Centro
de Operaciones de Noroccidente con sede en Huehuetenango; Centro de Operaciones
Suroccidente con sede en Retalhuleu y una Planta de Producción de mosca del Mediterráneo
estéril ubicada en Barberena, Santa Rosa, y un Centro de Empaque de Adulto Frío en Retalhuleu.</p>
             
            <p class="text-justify">La ubicación de la plaga se realiza por medio de trampas con atrayentes alimenticios y sexuales,
que capturan especímenes en estado adulto por muestreo de frutos hospedantes para la
detección de estados inmaduros como larva. Para el control de la mosca del Mediterráneo, se
utiliza un manejo integrado de la plaga, el cual integra una serie de acciones, tales como el control
por aspersiones de un producto de origen natural aprobado para su uso en la agricultura orgánica,
el control mecánico, el control AUTOCIDA o Técnica del Insecto Estéril (TIE) y el control legal o
puestos de cuarentena interna.</p>

            <p class="text-justify">Las acciones de trabajo que el Programa MOSCAMED realiza para el control y erradicación de la
mosca del Mediterráneo del territorio guatemalteco, se enmarcan dentro del estado de derecho y
tienen su base legal en el acuerdo gubernativo que declara de emergencia nacional el control de la
plaga del 19 de mayo de 1975. Además del convenio, para proteger las cosechas de los daños
causados por la plaga de la mosca del Mediterráneo por parte del Gobierno de Guatemala y de los
Estados Unidos Mexicanos del 15 de noviembre de 1975; y la aprobación por parte del Congreso
de la República de Guatemala de dicho convenio en el decreto No. 21-76 del 9 de junio de 1976.</p>

            <p class="text-justify">Asimismo, se basa en el Memorándum de entendimiento suscrito entre el Ministerio de
Agricultura, Ganadería y Alimentación de Guatemala y el Departamento de Agricultura de los
Estados Unidos de América del 21 de febrero de 1977, el acuerdo cooperativo entre el Ministerio
de Agricultura de Guatemala y el Departamento de Agricultura de los Estados Unidos de América
del 22 de octubre de 1981 y el decreto número 43-2002 que aprueba el Memorándum de
entendimiento entre el Ministerio de Agricultura Ganadería y Alimentación y el Departamento de
Agricultura de los Estados Unidos de América del 9 de julio del 2002.</p>

            <p class="text-justify">Así también a nivel nacional el Programa MOSCAMED tiene su base legal en la Ley de Sanidad
Vegetal y Animal, Decreto No. 36-98 de fecha 6 de mayo de 1998, que tiene como objetivo velar
por la protección y sanidad de los vegetales, animales, especies forestales e hidrobiológicos y la
preservación de sus productos y subproductos no procesados, contra la acción perjudicial de
plagas y enfermedades de importancia económica y cuarentenaria, sin perjuicio para la salud
humana y el ambiente.</p>

            <p class="text-justify">Posteriormente, el 19 de mayo de 2014 se firma el Convenio de Cooperación entre los Gobiernos
de la República de Guatemala, los Estados Unidos de América y los Estados Unidos Mexicanos para
la Prevención, Detección, Supresión y Erradicación de la Mosca de la Fruta del Mediterráneo y
otras Moscas de la Fruta de Importancia Económica.</p>

            <p class="text-justify">Durante el año 2023, el Programa MOSCAMED continúa contribuyendo a propiciar el desarrollo
rural del país, protegiendo la producción de frutas en las áreas de acción del daño que ocasiona la
plaga y manteniendo las áreas libres en Guatemala, lo cual permitirá que el comercio de productos
hortofrutícolas de exportación estén libres de restricciones cuarentenarias por la infestación de la
mosca del Mediterráneo y a su vez, fortalecer la economía guatemalteca con nuevas
oportunidades para el desarrollo de la hortofruticultura, generando empleo, aumentando los
ingresos y beneficiando a miles de familias en el área rural.</p>
        </div><br>
    </div>
</template>
<script>

export default({
    data() {
        return {
            img:require("@/assets/slider/slide2.jpg"),
        }
        
    },
})
</script>

